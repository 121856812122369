<template>
  <div id="faq" class="decorated">
    <HeaderNavigation title="à propos d'Evenir" />
    <b-container class="main-container">
      <b-row class="no-gutters">
        <b-col class="body">
          <template v-for="question in sortedQuestions">
            <h3
              v-b-toggle="question.id"
              :key="`title_${question.id}`"
              class="text-evenir-title"
            >
              {{ question.title }}
            </h3>
            <b-collapse :id="question.id" :key="`answer_${question.id}`">
              <p>{{ question.answer }}</p>
              <p v-if="question.sendComplaintCallback" class="text-right">
                <b-form v-if="question.requiresInput">
                  <b-form-row>
                    <b-col>
                      <b-textarea
                        v-model="message"
                        placeholder="Posez votre question ici."
                        maxlength="300"
                        rows="3"
                        required
                        trim
                      />
                      {{ message.length }} / 300
                    </b-col>
                  </b-form-row>
                </b-form>
                <b-button
                  v-loader.disable="sendingComplaintId == question.id"
                  variant="dark"
                  @click="sendComplaint(question)"
                  :disabled="sendingComplaint"
                >
                  ENVOYER MA REQUÊTE
                </b-button>
              </p>
            </b-collapse>
          </template>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="modalEmailSent"
      title="Votre requête a bien été envoyée"
      ok-title="RETOUR ACCUEIL"
      ok-variant="dark"
      @ok="$router.push(routes.HOME)"
      ok-only
      centered
    >
      <b-row>
        <b-col>
          L'équipe EVENIR prend connaissance de votre réclamation et vous fait
          un retour directement sur votre boite mail dans les plus bref délai.
          Veuillez accepter nos sincères excuses pour la gêne occasionnée.
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import {
  ComplaintKind,
  DefaultComplaintSubject
} from '@/assets/js/Constants.js'
import * as FAQ from '@/utils/faq-utils.js'
import { Routes } from '@/router.js'

export default {
  name: 'faq',
  components: { HeaderNavigation },
  data() {
    return {
      questions: FAQ.Questions,
      message: '',
      sendingComplaint: false,
      sendingComplaintId: undefined,
      routes: Routes
    }
  },
  computed: {
    sortedQuestions() {
      return [...this.questions].sort((q1, q2) =>
        q1.context.localeCompare(q2.context)
      )
    },
    username() {
      return this.$store.state.userInfo.username
    }
  },
  methods: {
    sendComplaint(question) {
      if (question.context == FAQ.FaqContext.Booking) {
        this.$router.push(Routes.MY_BOOKINGS)
      } else {
        this.sendingComplaint = true
        this.sendingComplaintId = question.id
        this[question.sendComplaintCallback]()
          .then(() =>
            this.$nextTick(() => {
              this.$refs.modalEmailSent.show()
            })
          )
          .catch(err => console.error('Could not send complaint: ', err))
          .finally(() => {
            this.sendingComplaint = false
            this.sendingComplaintId = undefined
          })
      }
    },
    [FAQ.PROFILE_ISSUE]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.DefaultComplaint,
        DefaultComplaintSubject.ProfileIssue,
        { username: this.username }
      )
    },
    [FAQ.BILLING_ISSUE]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.DefaultComplaint,
        DefaultComplaintSubject.BillingIssue,
        { username: this.username }
      )
    },
    [FAQ.OTHER_QUESTION]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.DefaultComplaint,
        DefaultComplaintSubject.OtherQuestion,
        {
          username: this.username,
          message: this.message.substring(0, 300)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#faq {
  p {
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $text-light;
  }
}
</style>
