export const CANNOT_FIND_CP = 'cannotFindCP'
export const CANNOT_ACCESS_CP = 'cannotAccessCP'
export const CP_BUSY = 'cpBusy'
export const CP_NOT_WORKING = 'cpNotWorking'
export const GONNA_BE_LATE = 'gonnaBeLate'
export const PROFILE_ISSUE = 'profileIssue'
export const BILLING_ISSUE = 'billingIssue'
export const OTHER_QUESTION = 'otherQuestion'

export const FaqContext = Object.freeze({
  Default: 'Default',
  Booking: 'Booking'
})

export const Questions = [
  {
    id: CANNOT_FIND_CP,
    context: FaqContext.Booking,
    title: 'Je ne trouve pas le point de recharge',
    answer:
      'Dans le menu "Mes réservations", vous avez accès à toutes les informations pour localiser précisément votre point de recharge.\
Les informations semblent éronnées, et nous vous prions de nous excuser pour ce désagrément. Un avertissement sera envoyé au fournisseur. \
En attendant, vous pouvez relancer une recherche à partir du menu "Trouver un point de recharge" en cliquant sur GO directement, \
l\'application va vous rechercher un point de recharge à proximité',
    sendComplaintCallback: CANNOT_FIND_CP
  },
  {
    id: CANNOT_ACCESS_CP,
    context: FaqContext.Booking,
    title: 'Je ne peux pas accéder au point de recharge',
    answer:
      'Avez-vous lu toutes les informations liées à l\'accès au point de recharge disponibles dans "Mes réservations en cours" ? Peut-être un détail vous aurait échappé.\
Vérifiez aussi que vous êtes sur le point de recharge que vous avez réservé et au créneau horaire que vous avez indiqué.\
Si tel est le cas, il semble alors que le propriétaire ait oublié son rendez-vous, il en sera averti. \
En attendant, vous pouvez relancer une recherche à partir du menu "Trouver un point de recharge" en cliquant sur GO directement, \
l\'application va vous rechercher un point de recharge à proximité',
    sendComplaintCallback: CANNOT_ACCESS_CP
  },
  {
    id: CP_BUSY,
    context: FaqContext.Booking,
    title: 'Le point de recharge est déjà occupée',
    answer:
      "Vérifiez que vous êtes sur le bon point de recharge que vous avez réservé et au créneau horaire que vous avez indiqué.\
Il semblerait que l'utilisateur vous précédant a fait preuve d'un mauvais usage et il en sera averti. \
En attendant, vous pouvez relancer une recherche à partir du menu \"Trouver un point de recharge\" en cliquant sur GO directement, \
l'application va vous rechercher un point de recharge à proximité",
    sendComplaintCallback: CP_BUSY
  },
  {
    id: CP_NOT_WORKING,
    context: FaqContext.Booking,
    title: 'Le point de recharge ne fonctionne pas',
    answer:
      "Vérifiez vos branchements de câble de recharge au niveau du point de recharge, et aussi au niveau de votre véhicule.\
Si rien ne change, regardez si le propriétaire est présent pour qu'il vous apporte son aide.\
S'il n'y a personne, malheureusement ce point de recharge semble disfonctionner. Nous prévenons le propriétaire dans les plus brefs délais.\
En attendant, vous pouvez relancer une recherche à partir du menu \"Trouver un point de recharge\" en cliquant sur GO directement, \
l'application va vous rechercher un point de recharge à proximité.",
    sendComplaintCallback: CP_NOT_WORKING
  },
  {
    id: GONNA_BE_LATE,
    context: FaqContext.Booking,
    title: 'Je vais etre en retard',
    answer:
      "Les points de recharge sont des zones recherchées, et les mobiliser pénalise les autres utilisateurs, mais aussi l'hôte. \
		C'est pour cela qu'il n'est pas possible ni de décaller, ni d'annuler tel que prévu dans nos CGV/CGU."
  },
  {
    id: PROFILE_ISSUE,
    context: FaqContext.Default,
    title: "J'ai un problème sur mon profil",
    answer:
      'EVENIR se mettra en contact avec vous par e-mail dans les plus brefs délais pour vous demander plus de détails.',
    sendComplaintCallback: PROFILE_ISSUE
  },
  {
    id: BILLING_ISSUE,
    context: FaqContext.Default,
    title: "J'ai un problème sur ma facture",
    answer:
      'EVENIR se mettra en contact avec vous par e-mail dans les plus brefs délais pour vous demander plus de détails.',
    sendComplaintCallback: BILLING_ISSUE
  },
  {
    id: OTHER_QUESTION,
    context: FaqContext.Default,
    title: 'Autres questions',
    answer:
      'EVENIR se mettra en contact avec vous par e-mail dans les plus brefs délais pour vous demander plus de détails.',
    sendComplaintCallback: OTHER_QUESTION,
    requiresInput: true
  }
]
